import React from "react";
import { StyledEngineProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "context/AuthContext";
import AppRouter from "./app-router/Router";
import CssBaseline from "@mui/material/CssBaseline";
import classNames from "classnames";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      refetchOnMount: true,
      suspense: false,
    },
    mutations: {
      retry: false,
    },
  },
});

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <div
            className={classNames("App", {
              block: window.location.pathname.includes("app-updates"),
              "md:block hidden":
                !window.location.pathname.includes("app-updates"),
            })}
          >
            <CssBaseline />
            <AppRouter />
          </div>
          <div
            className={classNames(
              "md:hidden flex justify-center items-center h-[100vh] text-xl font-semibold",
              {
                hidden: window.location.pathname.includes("app-updates"),
              }
            )}
          >
            Please use admin on web
          </div>
        </AuthProvider>
      </QueryClientProvider>
      <Toaster
        toastOptions={{
          style: {
            maxWidth: 500,
          },
        }}
      />
    </StyledEngineProvider>
  );
}

export default App;

// Ingredients added
