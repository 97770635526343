import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const ManageNotifications = lazy(
  () => import("modules/manage-notifications/ManageNotifications")
);
export default function ManageNotificationsPage() {
  return (
    <SuspenseWrapper>
      <ManageNotifications />
    </SuspenseWrapper>
  );
}
