import React from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import PageLoader from "components/loaders/PageLoader";
import { getToken, removeToken, setUser } from "helpers/storage";
import { UserType } from "helpers/types/user.types";

type LoginDataTypes = {
  token: string;
  user: UserType;
};

interface AuthContextType {
  signin: (loginData: LoginDataTypes) => void;
  signout: () => void;
}
const AuthContext = React.createContext<AuthContextType>({
  signin: () => {},
  signout: () => {},
});

function AuthProvider({ children }: { children: React.ReactNode }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isBootstaping, setIsBootstraping] = React.useState(true);

  React.useEffect(() => {
    // check for token initally
    const token = getToken();

    if (!token) {
      setIsBootstraping(false);
    }
    setTimeout(() => {
      setIsBootstraping(false);
    }, 1500);
  }, []);

  const signin = React.useCallback(
    async (loginData: LoginDataTypes) => {
      try {
        await localStorage.setItem("token", loginData?.token);
        await setUser(loginData?.user);
        navigate("/dashboard");
      } catch (err) {
        console.log(err);
      }
    },
    [navigate]
  );

  const signout = React.useCallback(() => {
    removeToken();
    queryClient.setQueryData(["get-user-profile"], null);
    navigate("/login");
  }, [navigate, queryClient]);

  const value = React.useMemo(() => ({ signin, signout }), [signin, signout]);

  if (isBootstaping) {
    return <PageLoader />;
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

export { AuthProvider, useAuth };
