import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const AppUpdatesPage = lazy(() => import("modules/AppUpdates"));
export default function AppUpdates() {
  return (
    <SuspenseWrapper>
      <AppUpdatesPage />
    </SuspenseWrapper>
  );
}
