import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const ScansPage = lazy(() => import("modules/scans/Scans"));
export default function Scans() {
  return (
    <SuspenseWrapper>
      <ScansPage />
    </SuspenseWrapper>
  );
}
