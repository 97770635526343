import React, { useEffect } from "react";
import DrawerMenu from "./Drawer";
import useResponsive from "helpers/hooks/useResponsive";
import { ReactComponent as Dashboard } from "assets/icons/home.svg";
import { ReactComponent as Users } from "assets/icons/users.svg";
import { ReactComponent as Supplements } from "assets/icons/supplements.svg";
import { ReactComponent as Scans } from "assets/icons/scanner.svg";
import { ReactComponent as Brands } from "assets/icons/eye.svg";
import { ReactComponent as Categories } from "assets/icons/categories.svg";
import { ReactComponent as ProductTypes } from "assets/icons/product-types.svg";
import { ReactComponent as Banners } from "assets/icons/banners.svg";
import { ReactComponent as Potency } from "assets/icons/potency.svg";
import { ReactComponent as Notification } from "assets/icons/notification.svg";

const MENU_ITEMS = [
  {
    label: "Dashboard",
    icon: Dashboard,
    path: "/dashboard",
  },
  {
    label: "Users",
    icon: Users,
    path: "/users",
    sub_path: ["/user-details", "/details"],
  },
  {
    label: "Supplements",
    icon: Supplements,
    path: "/supplements",
    sub_path: ["/supplement-details", "/test-requests"],
  },
  {
    label: "Categories",
    icon: Categories,
    path: "/categories",
  },
  {
    label: "Brands",
    icon: Brands,
    path: "/brands",
  },
  {
    label: "Scans",
    icon: Scans,
    path: "/scans",
  },
  {
    label: "Supplement types",
    icon: ProductTypes,
    path: "/supplement-types",
  },
  {
    label: "Banners",
    icon: Banners,
    path: "/banners",
  },
  {
    label: "Manage Potency",
    icon: Potency,
    path: "/manage-potency",
  },
  {
    label: "Manage Notifications",
    icon: Notification,
    path: "/manage-notifications",
  },
];
function Sidebar({ children }: { children: React.ReactNode }) {
  const { isDesktop, isLaptop } = useResponsive();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (isDesktop || isLaptop) {
      setOpen(true);
    }
  }, [isDesktop, isLaptop]);

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };
  return (
    <DrawerMenu open={open} toggleDrawer={toggleDrawer} navs={MENU_ITEMS}>
      {children}
    </DrawerMenu>
  );
}

export default Sidebar;
