export const ROUTES = {
  DASHBOARD: "/dashboard",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:hash",
  NOT_FOUND: "/404",
  USERS: "/users",
  USER_DETAILS: "/user-details/:id",
  SCANS: "/scans",
  BRANDS: "/brands",
  BRAND_DETAILS: "/brand-details/:id",
  CATEGORIES: "/categories",
  CATEGORY_DETAILS: "/category-details/:id",
  PRODUCT_TYPES: "/supplement-types",
  SUPPLEMENTS: "/supplements",
  TRENDING_SUPPLEMENTS: "/trending-supplements",
  SUPPLEMENT_DETAILS: "/supplement-details/:id",
  TRENDING_SUPPLEMENT_DETAILS: "/trending-supplement-details/:id",
  ADD_BRAND: "/add-brand",
  ADD_SUPPLEMENT: "/add-supplement",
  TEST_REQUESTS: "/test-requests",
  BANNERS: "/banners",
  MANAGE_POTENCY: "/manage-potency",
  APP_UPDATES: "/app-updates",
  MANAGE_NOTIFICATIONS: "/manage-notifications",
};
