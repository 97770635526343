import { useEffect, useState } from "react";
import { ClickAwayListener, Fade, IconButton } from "@mui/material";
import classNames from "classnames";
import LogoutPrompt from "./LogoutPrompt";
import useToggle from "helpers/hooks/useToggle";
import { getUser } from "helpers/storage";
import { UserType } from "helpers/types/user.types";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";

type Props = {
  isOpen: boolean;
};

const Cta = ({ isOpen }: Props) => {
  const [showLogoutPrompt, toggle] = useToggle();
  const [showLogoutButton, setShowLogoutButton] = useState<boolean>(false);
  const [user, setUser] = useState<UserType | null>(null);

  const toggleLogoutButton = () => {
    setShowLogoutButton((prev) => !prev);
  };

  const getUserData = async () => {
    const user = await getUser();
    setUser(user);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleLogout = () => {
    toggle();
  };

  if (!user) return null;
  return (
    <>
      <div
        className={classNames(
          "relative flex justify-center items-center border-0 border-t border-solid border-[#E3EAF4] text-[#2A2B2F] cursor-pointer transition-all duration-200",
          {
            "py-4 px-5": isOpen,
            "py-3 px-1": !isOpen,
          }
        )}
        onClick={toggleLogoutButton}
      >
        <div className="h-12 w-12 flex justify-center items-center text-lg font-semibold border border-solid border-[#E3EAF4] rounded-full">
          {user?.name?.split(" ")[0]?.charAt(0)}
          {user?.name?.split(" ")[1]?.charAt(0)}
        </div>
        {isOpen && (
          <>
            <div className="font-semibold ml-3 flex-1">{user?.name}</div>
            <IconButton className="w-10 h-10">
              <ArrowLeft className="stroke-[#2A2B2F] rotate-90" />
            </IconButton>
          </>
        )}
        {showLogoutButton && (
          <LogoutButton
            toggle={toggleLogoutButton}
            onLogout={handleLogout}
            isDrawerOpen={isOpen}
          />
        )}
      </div>
      <LogoutPrompt isOpen={showLogoutPrompt} toggle={toggle} />
    </>
  );
};

export default Cta;

const LogoutButton = ({
  toggle,
  onLogout,
  isDrawerOpen,
}: {
  toggle: () => void;
  onLogout: () => void;
  isDrawerOpen;
}) => {
  return (
    <ClickAwayListener onClickAway={toggle}>
      <Fade
        in
        className={classNames(
          "options p-2 rounded-lg z-[9] bg-[#fff] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] py-3 transition ease-in-out duration-300",
          {
            "absolute bottom-14 right-2": isDrawerOpen,
            "fixed bottom-16 left-2": !isDrawerOpen,
          }
        )}
      >
        <div>
          <div
            className="font-medium text-red px-3 py-1 hover:text-black rounded-md"
            onClick={onLogout}
          >
            Logout
          </div>
        </div>
      </Fade>
    </ClickAwayListener>
  );
};
