import RequireAuth from "app-router/RequireAuth";
import Sidebar from "components/sidebar/Sidebar";
import { Outlet } from "react-router-dom";

const PageLayout = () => {
  return (
    <RequireAuth>
      <Sidebar>
        <div className="flex flex-col w-full bg-secondary-color min-h-screen md:p-8 p-4">
          <Outlet />
        </div>
      </Sidebar>
    </RequireAuth>
  );
};

export default PageLayout;
