import * as React from "react";
import { styled, Theme, CSSObject, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerContent from "./DrawerContent";
import { myTheme } from "styles/theme";
import { AppBar, Toolbar, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

const drawerWidth = 275;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function DrawerMenu({
  children,
  navs,
  open,
  toggleDrawer,
}: {
  children: React.ReactNode;
  navs: any;
  open: boolean;
  toggleDrawer: any;
}) {
  const theme = useTheme();
  const isSmallerDevice = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      component="nav"
      sx={{
        display: { md: "flex" },
      }}
    >
      <CssBaseline />
      <AppBar
        position="relative"
        elevation={0}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          display: { md: "none" },
          backgroundColor: myTheme.colors.secondary,
          borderBottom: `1px solid ${myTheme.colors.gray1}`,
        }}
      >
        <Toolbar className="py-3 px-6 flex items-center justify-between">
          <Link to="/">
            <figure className="p-0 m-0">
              <img src="/logo.svg" alt="trueview" height={21} />
            </figure>
          </Link>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
          >
            <MenuIcon sx={{ color: "#000" }} />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/*
          ==========================================
          ======= DRAWER FOR SMALLER DEVICES =======
          ==========================================
      */}

      {isSmallerDevice && (
        <MuiDrawer
          variant="temporary"
          open={open}
          PaperProps={{
            className: "border-r-0",
            style: { width: drawerWidth },
          }}
          sx={{ zIndex: 99, display: { md: "none" } }}
          onBackdropClick={toggleDrawer}
        >
          <DrawerContent
            open={open}
            navs={navs}
            toggleDrawer={toggleDrawer}
            onSelectMenu={toggleDrawer}
          />
        </MuiDrawer>
      )}

      {/*
          ===========================================
          ====== DRAWER FOR DESKTOPS | LAPTOPS ======
          ===========================================
      */}
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          className: "border-r-0",
        }}
        sx={{
          display: { xs: "none", md: "block", zIndex: 100 },
        }}
      >
        <DrawerContent open={open} navs={navs} toggleDrawer={toggleDrawer} />
      </Drawer>

      <Box
        component="main"
        sx={{ flexGrow: 1 }}
        className="bg-secondary-color min-h-screen overflow-x-hidden"
      >
        {children}
      </Box>
    </Box>
  );
}

export default DrawerMenu;
