import { Navigate, Outlet } from "react-router-dom";
import AuthHeader from "components/AuthHeader";
import { getToken } from "helpers/storage";

function AuthLayout() {
  const isLoggedIn = getToken();
  return (
    <div className="bg-secondary-color flex flex-col">
      <AuthHeader />
      {isLoggedIn ? <Navigate to="/dashboard" /> : <Outlet />}
    </div>
  );
}

export default AuthLayout;
