import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const CategoryDetails = lazy(
  () => import("modules/category-details/CategoryDetails")
);
export default function CategoryDetailsPage() {
  return (
    <SuspenseWrapper>
      <CategoryDetails />
    </SuspenseWrapper>
  );
}
