import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const TrendingSupplementsPage = lazy(
  () => import("modules/trending-supplements/TrendingSupplements")
);
export default function Supplements() {
  return (
    <SuspenseWrapper>
      <TrendingSupplementsPage />
    </SuspenseWrapper>
  );
}
