import { Routes, Route, Navigate } from "react-router-dom";
import AuthLayout from "layouts/AuthLayout";
import PageLayout from "layouts/PageLayout";
import Login from "pages/login";
import Dashboard from "pages/dashboard";
import Page404 from "pages/404";
import Users from "pages/users";
import UserDetails from "pages/user-details";
import Scans from "pages/scans";
import Brands from "pages/brands";
import { getToken } from "helpers/storage";
import { ROUTES } from "./routes";
import CategoriesPage from "pages/categories";
import CategoryDetails from "pages/category-details";
import ProductTypesPage from "pages/ProductTypes";
import Supplements from "pages/supplements";
import TrendingSupplements from "pages/trending-supplements";
import BrandDetailsPage from "pages/brands-details";
import SupplementDetailsPage from "pages/supplement-details";
import TreningSupplementDetailsPage from "pages/trending-supplement-details";
import AddBrandPage from "pages/add-brand";
import AddSupplementPage from "pages/add-supplement";
import TestRequestsPage from "pages/test-requests";
import BannersPage from "pages/banners";
import ManagePotencyPage from "pages/manage-potency";
import AppUpdates from "pages/app-updates";
import ManageNotificationsPage from "pages/manage-notifications";

function AppRouter() {
  return (
    <Routes>
      {/* dashboard routes */}
      <Route element={<PageLayout />}>
        <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
        <Route path={ROUTES.USERS} element={<Users />} />
        <Route path={ROUTES.USER_DETAILS} element={<UserDetails />} />
        <Route path={ROUTES.SCANS} element={<Scans />} />
        <Route path={ROUTES.BRANDS} element={<Brands />} />
        <Route path={ROUTES.ADD_BRAND} element={<AddBrandPage />} />
        <Route path={ROUTES.BRAND_DETAILS} element={<BrandDetailsPage />} />
        <Route path={ROUTES.CATEGORIES} element={<CategoriesPage />} />
        <Route path={ROUTES.CATEGORY_DETAILS} element={<CategoryDetails />} />
        <Route path={ROUTES.PRODUCT_TYPES} element={<ProductTypesPage />} />
        <Route path={ROUTES.SUPPLEMENTS} element={<Supplements />} />
        <Route
          path={ROUTES.TRENDING_SUPPLEMENTS}
          element={<TrendingSupplements />}
        />
        <Route path={ROUTES.ADD_SUPPLEMENT} element={<AddSupplementPage />} />
        <Route path={ROUTES.TEST_REQUESTS} element={<TestRequestsPage />} />
        <Route path={ROUTES.BANNERS} element={<BannersPage />} />
        <Route
          path={ROUTES.MANAGE_NOTIFICATIONS}
          element={<ManageNotificationsPage />}
        />
        <Route path={ROUTES.MANAGE_POTENCY} element={<ManagePotencyPage />} />
        <Route
          path={ROUTES.SUPPLEMENT_DETAILS}
          element={<SupplementDetailsPage />}
        />
        <Route
          path={ROUTES.TRENDING_SUPPLEMENT_DETAILS}
          element={<TreningSupplementDetailsPage />}
        />
      </Route>
      {/* authentication routes */}
      <Route element={<AuthLayout />}>
        <Route path={ROUTES.LOGIN} element={<Login />} />
      </Route>
      <Route path={ROUTES.APP_UPDATES} element={<AppUpdates />} />
      {/* common routes */}
      <Route path="/404" element={<Page404 />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
      <Route
        index
        element={
          getToken() ? (
            <Navigate to={ROUTES.DASHBOARD} />
          ) : (
            <Navigate to={ROUTES.LOGIN} />
          )
        }
      />
    </Routes>
  );
}

export default AppRouter;
