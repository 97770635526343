import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const SupplementsPage = lazy(() => import("modules/supplements/Supplements"));
export default function Supplements() {
  return (
    <SuspenseWrapper>
      <SupplementsPage />
    </SuspenseWrapper>
  );
}
