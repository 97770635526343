import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const AddSupplement = lazy(
  () => import("modules/add-supplement/AddSupplement")
);
export default function AddSupplementPage() {
  return (
    <SuspenseWrapper>
      <AddSupplement />
    </SuspenseWrapper>
  );
}
