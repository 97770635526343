import classNames from "classnames";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, NavLink, useLocation } from "react-router-dom";
import { myTheme } from "styles/theme";
import { styled } from "@mui/material/styles";
import Cta from "./Cta";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  fontFamily: "Outfit-Bold",
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  borderRadius: ".5rem",
  fontFamily: "Outfit-Bold",
  color: myTheme.colors.darkGray,
  ".active > &, &:hover": {
    background: "var(--blue-tint)",
  },
}));

const DrawerContent = ({
  open,
  toggleDrawer,
  navs,
  onSelectMenu,
}: {
  open: boolean;
  toggleDrawer: () => void;
  navs: any[];
  onSelectMenu?: () => void;
}) => {
  const location = useLocation();
  return (
    <>
      <DrawerHeader
        className={`flex relative ${
          open ? "px-8 py-7 justify-between" : "justify-center"
        } py-5`}
      >
        {open ? (
          <>
            <Link to="/">
              <figure className="p-0 m-0 flex justify-center my-auto">
                <img src="/logo-small.svg" alt="trueview" height={21} />
              </figure>
            </Link>

            <IconButton
              onClick={toggleDrawer}
              className="absolute right-1 bg-[#F5F4F8] rounded-lg w-6 h-10"
            >
              <ArrowLeft className="stroke-primary-color" />
            </IconButton>
          </>
        ) : (
          <IconButton onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        )}
      </DrawerHeader>
      <Divider />
      <List className="flex-1">
        {navs.map((item) => {
          const Icon = item.icon;
          console.log(item.sub_path);
          return (
            <ListItem
              key={item.label}
              disablePadding
              sx={{ display: "block" }}
              onClick={onSelectMenu}
              className={classNames("", { "px-1 mb-1": !open })}
            >
              <NavLink
                to={item.path || ""}
                className={classNames("color-inherit no-underline", {
                  active:
                    location.pathname?.includes(item.path) ||
                    item.sub_path?.some((path: string) =>
                      location.pathname?.includes(path)
                    ),
                })}
              >
                <StyledListItemButton
                  className={`${open ? "mx-2 my-1 px-5 py-3" : ""}`}
                  sx={{
                    justifyContent: open ? "initial" : "center",
                  }}
                  title={item.label}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Icon
                      className={
                        item.path !== "/manage-potency" && "stroke-[#161720]"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </StyledListItemButton>
              </NavLink>
            </ListItem>
          );
        })}
      </List>
      <Cta isOpen={open} />
    </>
  );
};

export default DrawerContent;
