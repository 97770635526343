import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const AddBrand = lazy(() => import("modules/add-brand/AddBrand"));
export default function AddBrandPage() {
  return (
    <SuspenseWrapper>
      <AddBrand />
    </SuspenseWrapper>
  );
}
