import UIModal from "components/UIModal";
import { Button } from "components/buttons";
import { useAuth } from "context/AuthContext";

type Props = {
  toggle: () => void;
  isOpen: boolean;
};

const LogoutPrompt = ({ isOpen, toggle }: Props) => {
  const { signout } = useAuth();

  return (
    <UIModal show={isOpen} toggle={toggle} title={""}>
      <div className="text-center text-xl font-medium">
        Are you sure you want to log out?
      </div>
      <div className="flex items-center gap-3 mt-7 justify-center flex-wrap">
        <Button
          color="inherit"
          rounded={1}
          variant="outlined"
          className="text-black"
          onClick={toggle}
        >
          No
        </Button>
        <Button rounded={1} onClick={signout}>
          Yes, Logout
        </Button>
      </div>
    </UIModal>
  );
};

export default LogoutPrompt;
