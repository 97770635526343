import React, { useLayoutEffect } from "react";
import styled, { css } from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { twMerge } from "tailwind-merge";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { DialogTitleProps } from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface Props {
  show: boolean;
  toggle?: () => void;
  title?: string | React.ReactNode;
  subTitle?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  papperProps?: any;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  scrolllock?: boolean | 0 | 1;
}

const _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);

const StyledDialog = styled(Dialog)<{ scrolllock?: boolean | 0 | 1 }>`
  .MuiDialogContent-root {
    padding-right: 8px;
    z-index: 1;
    &::-webkit-scrollbar {
      width: 4px;
      margin: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      height: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.1rem;
      height: 2px;
      background-color: #dddddd;
    }

    ${({ scrolllock }) =>
      scrolllock &&
      css`
        overflow: hidden;
      `}
  }
`;

function UIModal({
  title,
  subTitle,
  show,
  toggle,
  footer,
  children,
  papperProps,
  maxWidth = "sm",
  scrolllock = 0,
}: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useLayoutEffect(() => {
    if (_iOSDevice) {
      if (show) {
        document.body.classList.add("freezIphone");
      } else {
        document.body.classList.remove("freezIphone");
      }
    }
  }, [show]);

  return (
    <div className="relative">
      <StyledDialog
        scrolllock={scrolllock || 0}
        open={show}
        onClose={toggle}
        PaperProps={{
          ...papperProps,
          style: {
            overflow: "unset",
          },
          className: twMerge(
            "p-8 rounded-2xl h-fit w-[472px] max-w-[90%] max-h-[calc(100vh-100px)] overflow-x-hidden overflow-y-auto",
            papperProps?.className
          ),
        }}
        fullScreen={fullScreen}
      >
        {toggle && <CloseButton toggle={toggle} />}

        <BootstrapDialogTitle>
          <div className="text-2xl font-semibold">{title}</div>
          {subTitle && (
            <div className="text-darkGray font-normal mt-2">{subTitle}</div>
          )}
        </BootstrapDialogTitle>

        <DialogContent className="p-0">{children}</DialogContent>

        {!!footer && <DialogActions>{footer}</DialogActions>}
      </StyledDialog>
    </div>
  );
}

export default UIModal;

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children } = props;

  return <h2 className="p-0 text-lg mt-0 font-bold mb-3 mr-3">{children}</h2>;
};

const CloseButton = ({ toggle }: { toggle: () => void }) => {
  return (
    <div
      onClick={toggle}
      className="group bg-white hover:bg-[#989797] h-11 w-11 rounded-full shadow cursor-pointer flex justify-center items-center absolute md:-right-[50px] -right-[20px] md:top-0 -top-[20px] z-[10000] transition-all duration-300"
    >
      <CloseRoundedIcon className="h-7 w-7 fill-[#222222] group-hover:fill-white transition-all duration-300" />
    </div>
  );
};
