import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const SupplementDetails = lazy(
  () => import("modules/supplement-details/SupplementDetails")
);
export default function SupplementDetailsPage() {
  return (
    <SuspenseWrapper>
      <SupplementDetails />
    </SuspenseWrapper>
  );
}
