import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const ProductTypes = lazy(() => import("modules/product-types/ProductTypes"));
export default function ProductTypesPage() {
  return (
    <SuspenseWrapper>
      <ProductTypes />
    </SuspenseWrapper>
  );
}
