import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const TestRequests = lazy(() => import("modules/test-requests/TestRequests"));
export default function TestRequestsPage() {
  return (
    <SuspenseWrapper>
      <TestRequests />
    </SuspenseWrapper>
  );
}
