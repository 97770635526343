import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const LoginPage = lazy(() => import("modules/login/Login"));
export default function Login() {
  return (
    <SuspenseWrapper>
      <LoginPage />
    </SuspenseWrapper>
  );
}
