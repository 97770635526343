import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const BrandDetails = lazy(() => import("modules/brand-details/BrandDetails"));
export default function BrandDetailsPage() {
  return (
    <SuspenseWrapper>
      <BrandDetails />
    </SuspenseWrapper>
  );
}
