import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const ManagePotency = lazy(
  () => import("modules/manage-potency/ManagePotency")
);
export default function ManagePotencyPage() {
  return (
    <SuspenseWrapper>
      <ManagePotency />
    </SuspenseWrapper>
  );
}
