import { UserType } from "./types/user.types";

const setToken = (token: string) => {
  localStorage.setItem("token", token);
};
const getToken = () => {
  return localStorage.getItem("token");
};

const removeToken = () => {
  localStorage.removeItem("token");
};

const setUser = (user: UserType) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const getUser = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  }
  return user;
};

export { setToken, getToken, removeToken, setUser, getUser };
