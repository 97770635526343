import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const Banners = lazy(() => import("modules/banners/Banners"));
export default function BannersPage() {
  return (
    <SuspenseWrapper>
      <Banners />
    </SuspenseWrapper>
  );
}
