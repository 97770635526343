import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const UserDetailsPage = lazy(() => import("modules/user-details/UserDetails"));
export default function UserDetails() {
  return (
    <SuspenseWrapper>
      <UserDetailsPage />
    </SuspenseWrapper>
  );
}
