import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const Categories = lazy(() => import("modules/categories/Categories"));
export default function CategoriesPage() {
  return (
    <SuspenseWrapper>
      <Categories />
    </SuspenseWrapper>
  );
}
